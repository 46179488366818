<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="error"
        class="text-pre-wrap"
        v-if="alertDanger.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertDanger"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="refundEmailApproval2SubmitDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          {{ this.model.name.singular }}
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to Send Refund Email
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRefundEmailApproval2Submit()"
          >
            Send
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="refundEmailApproval2SubmitDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="refundEmailRejectDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Reject Refund Email
        </v-toolbar>

        <v-card-text>
          Please confirm that you wish to Reject this Refund Email
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRefundEmailReject()"
          >
            Reject
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="refundEmailRejectDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span
            v-if="
              isTaxpodApproval1AdminRoles && !btnDisabled && !editMode
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  class="hidden-xs-only text-white mr-5"
                  :loading="apiSubmit.isLoading"
                  @click="refundEmailRejectDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-remove
                  </v-icon>
                </v-btn>
              </template>
              <span>Reject Refund Email</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="refundEmailApproval2SubmitDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-check
                  </v-icon>
                </v-btn>
              </template>
              <span>Approve Refund Email</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div class="pa-3">
        <v-row
          class="d-flex justify-space-between"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <v-col
            cols="6"
            style="border-right: 1px solid lightgrey"
            class="pl-5"
          >
            <v-expansion-panels v-model="panel" focusable elevation="0">
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Refund Details</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div
                    class="pa-2 ma-2"
                    :style="{ backgroundColor: HRDC_CARD_COLOR }"
                    outlined
                  >
                    <!-- taxPOD Price -->
                    <v-col class="d-flex px-0" cols="12" sm="12" md="12">
                      <v-text-field
                        dense
                        filled
                        disabled
                        label="taxPOD Price"
                        v-model="formattedPrice"
                        :error-messages="errorField.taxpodPrice"
                      ></v-text-field>
                    </v-col>

                    <!--Screenshot Refund Bank Account Details-->
                    <v-col
                      class="d-flex pb-0 px-0"
                      cols="12"
                      lg="12"
                      sm="12"
                      md="12"
                    >
                      <div class="w-100">
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          small-chips
                          disabled
                          label="Screenshot Refund Bank Account Details"
                          v-model="formData.ssRefundBankAccDetails"
                          :truncate-length="1000"
                        ></v-file-input>
                      </div>
                    </v-col>

                    <!--HRDC Invoice-->
                    <v-row class="pl-3">
                      <v-col class="d-flex pl-0" cols="12" lg="8" sm="8" md="8">
                        <div class="w-100">
                          <v-file-input
                            :prepend-icon="null"
                            prepend-inner-icon="mdi-paperclip"
                            dense
                            filled
                            small-chips
                            label="HRDC Invoice (Auto Insert)"
                            disabled
                            v-model="formData.manualInvoice"
                            :error-messages="errorField.manualInvoice"
                            :truncate-length="1000"
                          ></v-file-input>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="4" sm="4" md="4" class="pl-0 pr-5">
                        <v-text-field
                          dense
                          filled
                          v-model="formData.manualInvoiceToHrdc"
                          :error-messages="errorField.manualInvoiceToHrdc"
                          label="HRDC Invoice No."
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!--Client/taxPOD Invoice-->
                    <v-row class="pl-3">
                      <v-col class="d-flex pl-0" cols="12" lg="8" sm="8" md="8">
                        <div
                          v-cloak
                          @drop.prevent="
                            addDropMultipleFile(
                              $event,
                              'autoGeneratedTaxpodInvoice',
                              'autoGeneratedTaxpodInvoiceNo',
                              'pdf.*'
                            )
                          "
                          @dragover.prevent
                          class="w-100"
                        >
                          <v-file-input
                            :prepend-icon="null"
                            prepend-inner-icon="mdi-paperclip"
                            dense
                            filled
                            small-chips
                            multiple
                            label="Client/taxPOD Invoice"
                            accept=".pdf"
                            @change="
                              scanPdf(
                                $event,
                                'autoGeneratedTaxpodInvoice',
                                'autoGeneratedTaxpodInvoiceNo'
                              )
                            "
                            v-model="formData.autoGeneratedTaxpodInvoice"
                            :error-messages="
                              errorField.autoGeneratedTaxpodInvoice
                            "
                            :truncate-length="1000"
                            disabled
                          ></v-file-input>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="4" sm="4" md="4" class="pl-0 pr-5">
                        <v-text-field
                          dense
                          filled
                          v-model="formData.autoGeneratedTaxpodInvoiceNo"
                          :error-messages="
                            errorField.autoGeneratedTaxpodInvoiceNo
                          "
                          label="Invoice No."
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!--Credit Note-->
                    <v-row class="pl-3">
                      <v-col class="d-flex pl-0" cols="12" lg="8" sm="8" md="8">
                        <div
                          v-cloak
                          @drop.prevent="
                            addDropMultipleFile(
                              $event,
                              'creditNote',
                              'creditNoteNo',
                              'pdf.*'
                            )
                          "
                          @dragover.prevent
                          class="w-100"
                        >
                          <v-file-input
                            :prepend-icon="null"
                            prepend-inner-icon="mdi-paperclip"
                            dense
                            filled
                            small-chips
                            multiple
                            label="Credit Note"
                            @change="
                              scanPdf($event, 'creditNote', 'creditNoteNo')
                            "
                            v-model="formData.creditNote"
                            :error-messages="errorField.creditNote"
                            :truncate-length="1000"
                          ></v-file-input>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="4" sm="4" md="4" class="pl-0 pr-5">
                        <v-text-field
                          dense
                          filled
                          v-model="formData.creditNoteNo"
                          :error-messages="errorField.creditNoteNo"
                          label="CN No."
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!--Payment Advice From HRDC-->
                    <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                      <div
                        v-cloak
                        @drop.prevent="
                          addDropFile(
                            $event,
                            'paymentAdviceFromHrdc',
                            null,
                            'image/*'
                          )
                        "
                        @dragover.prevent
                        class="w-100"
                      >
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          small-chips
                          @change="
                            checkAttachment($event, 'paymentAdviceFromHrdc')
                          "
                          accept="image/*"
                          label="Payment Advice From HRDC"
                          v-model="formData.paymentAdviceFromHrdc"
                          :error-messages="errorField.paymentAdviceFromHrdc"
                          :truncate-length="1000"
                        ></v-file-input>
                      </div>
                    </v-col>

                    <!--Payment Advice From Client (Stripe/iPay/...)-->
                    <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                      <div
                        v-cloak
                        @drop.prevent="
                          addDropFile(
                            $event,
                            'paymentAdviceFromClient',
                            null,
                            null
                          )
                        "
                        @dragover.prevent
                        class="w-100"
                      >
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          small-chips
                          @change="
                            checkAttachment($event, 'paymentAdviceFromClient')
                          "
                          label="Payment Advice From Client"
                          v-model="formData.paymentAdviceFromClient"
                          :error-messages="errorField.paymentAdviceFromClient"
                          :truncate-length="1000"
                        ></v-file-input>
                      </div>
                    </v-col>

                    <!--Payment Advice Type-->
                    <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                      <v-select
                        :items="paymentAdviceItems"
                        v-model="formData.paymentAdviceType"
                        filled
                        dense
                        label="Payment Advice Type"
                      ></v-select>
                    </v-col>

                    <!--Other Attachments-->
                    <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                      <div
                        v-cloak
                        @drop.prevent="
                          addDropFile(
                            $event,
                            'refundOtherAttachments',
                            null,
                            null
                          )
                        "
                        @dragover.prevent
                        class="w-100"
                      >
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          multiple
                          small-chips
                          @change="
                            checkAttachment($event, 'refundOtherAttachments')
                          "
                          label="Other Attachments"
                          v-model="formData.refundOtherAttachments"
                          :error-messages="errorField.refundOtherAttachments"
                          :truncate-length="1000"
                          persistent-hint
                        ></v-file-input>
                      </div>
                    </v-col>

                    <!--Refund Remarks-->
                    <v-col class="d-flex pl-0" cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="formData.refundRemarks"
                        filled
                        dense
                        no-resize
                        label="Refund Remarks"
                      ></v-textarea>
                    </v-col>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div v-if="imageUrl">
              <hr />
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <iframe
                  :src="imageUrl"
                  alt=""
                  width="100%"
                  height="900"
                ></iframe>
              </v-col>
            </div>
          </v-col>

          <v-col cols="6">
            <span v-for="(attn, index) in refundEmailAttachments" :key="index">
              <v-btn
                @click="previewOrDownloadAttachment(index, 'preview')"
                :color="HRDC_BLUE_THEME_COLOR"
                elevation="2"
                outlined
                class="mr-1 mb-1"
              >
                {{ attn }}
              </v-btn>
            </span>

            <span v-show="Object.keys(otherRefundEmailAttachments).length > 0">
              <v-btn
                v-for="(otherAttn, index) in otherRefundEmailAttachments"
                :key="index"
                :color="HRDC_BLUE_THEME_COLOR"
                class="mr-1 mb-1"
                outlined
                elevation="2"
                @click.prevent="previewOrDownloadAttachment(index, 'preview')"
              >
                {{ otherAttn }}
              </v-btn>
            </span>

            <hr />

            <v-list flat subheader>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"
                    ><v-app-bar flat outlined elevation="0">
                      <span>
                        <span class="text-button font-weight-bold"
                          >Refund Details</span
                        >
                      </span>
                    </v-app-bar></span
                  >
                </template>
                <span
                  >Finance will process the refund based on below
                  information</span
                >
              </v-tooltip>

              <v-alert
                v-model="checklistUncheckErr"
                color="red"
                dense
                text
                type="error"
                class="mt-2"
                >Please check all the refund details!</v-alert
              >

              <v-list-item-group v-model="refundCheckedlist" multiple>
                <template v-for="(rcItem, index) in refundChecklistItems">
                  <v-list-item :key="index">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ rcItem.text }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-html="rcItem.value"></span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>

        <!-- Refund Email Approval 2 Date -->
        <v-col cols="12" sm="6" md="6" v-show="false">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formData.refundEmailApproval2Date"
                label="Refund Email Approval 2 Date (YYYY-MM-DD)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.refundEmailApproval2Date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    formattedPrice() {
      return `RM ${parseFloat(this.formData.taxpodPrice).toLocaleString()}`;
    },
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiGetRefundAttachment: new Api(),
    apiScanPdfText: new Api(),
    apiGetFileAttachment: new Api(),
    apiDownloadOrPreviewAttachment: new Api(),
    apiSubmit: new Api(),
    apiReject: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    alertDanger: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      taxpodPrice: null,
      refundEmailApproval2Date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      remarks: null,
      manualInvoiceToHrdc: null,
      manualInvoice: {
        name: "HRDC Invoice",
      },
      ssRefundBankAccDetails: {
        name: "Screenshot of Refund Bank Account Details.png",
      },
      autoGeneratedTaxpodInvoice: [],
      autoGeneratedTaxpodInvoiceNo: null,
      paymentAdviceFromHrdc: null,
      paymentAdviceFromClient: null,
      paymentAdviceType: "Stripe",
      creditNote: [],
      creditNoteNo: null,
      oldCompanyName: null,
      oldPicName: null,
      oldSsmNo: null,
      bankUuid: null,
      oldBankAccount: null,
      paymentReceivedDate: null,
      refundOtherAttachments: [],
      refundRemarks: null,
      oldBankAccountHolderName: null,
    },
    errorField: {
      applicationNo: null,
      refundEmailApproval2Date: null,
      remarks: null,
      manualInvoice: null,
      manualInvoiceToHrdc: null,
      refundOtherAttachments: null,
    },
    refundEmailAttachments: {
      autoGeneratedInvoice: "Client/taxPOD Invoice",
      creditNoteGenerated: "Credit Note",
      manualInvoiceToHrdc: "HRDC Invoice",
      paymentAdviceFromClient: "Payment Advice From Client",
      paymentAdviceFromHrdc: "Payment Advice From HRDC",
      ssRefundBankAccDetails: "Screenshot Refund Bank Account Details",
    },
    otherRefundEmailAttachments: {},
    loadingDialog: false,
    refundEmailApproval2SubmitDialog: false,
    refundEmailRejectDialog: false,
    editMode: false,
    btnDisabled: false,
    paymentAdviceItems: null,
    imageUrl: null,
    panel: [0],
    refundCheckedlist: [],
    refundChecklistItems: [
      {
        text: "Client Invoice No.",
        slug: "autoGeneratedTaxpodInvoiceNo",
        value: null,
        read: true,
      },
      {
        text: "HRDC Invoice No.",
        slug: "manualInvoiceToHrdc",
        value: null,
        read: true,
      },
      {
        text: "Payment Advice from Client",
        slug: "paymentAdviceType",
        value: null,
        read: true,
      },
      {
        text: "Payment Advice from HRDC",
        slug: "manualInvoiceToHrdc",
        value: null,
        read: true,
      },
      {
        text: "Credit Note",
        slug: "creditNoteNo",
        value: null,
        read: true,
      },
      {
        text: "Screenshot of Bank Account Details",
        slug: "ssOfBankAccDetails",
        value: null,
        read: false,
      },
      {
        text: "Refund Bank Account Details",
        slug: "refundBankAccDetails",
        value: null,
        read: false,
      },
    ],
    checklistUncheckErr: false,
  }),
  watch: {
    "formData.creditNoteNo": function (newVal) {
      let creditNoteObj = this.refundChecklistItems.find(
        (item) => item.slug === "creditNoteNo"
      );

      if (newVal.length > 0) {
        if (creditNoteObj) {
          if (typeof this.formData.creditNoteNo === "string") {
            creditNoteObj.value = this.formData.creditNoteNo;
          } else {
            creditNoteObj.value = this.formData.creditNoteNo.join(",");
          }
        }
      } else {
        creditNoteObj.value = null;
      }
    },
    "formData.paymentAdviceType": function (newVal) {
      let paymentAdviceTypeObj = this.refundChecklistItems.find(
        (item) => item.slug === "paymentAdviceType"
      );

      if (newVal) {
        paymentAdviceTypeObj.value = newVal;
      }
    },
  },
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_approval_2/create-refund-email-approval-2`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiDownloadOrPreviewAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/azure-download-or-preview-application-attachment`,
      },
      apiGetFileAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment`,
        method: "post",
      },
      apiGetRefundAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment-array`,
        method: "post",
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_submission/scan-pdf`,
        method: "post",
      },
      apiReject: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/reject-refund-email`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc.data.stageId];
    this.rolesAssignation(this.auth.roles);
    if (!this.isTaxpodApproval2AdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.refundEmailApproval2
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.refundEmailApproval1
    );

    // Assign payment advice options.
    this.paymentAdviceItems = this.paymentAdviceOpts;

    this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
      try {
        const { data, company } = response;
        const attachments = {};
        let otherRefundAttnCount = 0;
        const stagePriorities = [
          this.hrdcData.stagePriority.creditNote,
          this.hrdcData.stagePriority.creditNoteGenerated,
          this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice,
          this.hrdcData.stagePriority.paymentAdviceFromHrdc,
          this.hrdcData.stagePriority.paymentAdviceFromClient,
          this.hrdcData.stagePriority.refundEmailOtherAttachments,
        ];

        if (this.editMode) {
          this.formData.refundEmailApproval2Date = moment(
            data.ApplicationLog.find(
              (obj) =>
                obj.stageId == this.hrdcData.stagePriority.refundEmailApproval2
            ).startDate
          ).format("YYYY-MM-DD");

          for (const stageId of stagePriorities) {
            let filesArr = [];
            const attachment = data.ApplicationAttachment.filter(
              (item) => item.stageId == stageId
            );

            if (attachment.length > 0) {
              for (const atch of attachment) {
                // Cater for other refund attachments.
                if (
                  stageId ==
                  this.hrdcData.stagePriority.refundEmailOtherAttachments
                ) {
                  if (!attachments[stageId]) {
                    attachments[stageId] = {};
                  }

                  attachments[stageId][otherRefundAttnCount] = {
                    fileUrl: atch.fileUrl,
                    fileName: atch.oriFileName,
                  };
                  otherRefundAttnCount++;
                } else {
                  filesArr.push(atch.fileUrl);

                  attachments[stageId] = {
                    fileName: attachment.oriFileName,
                  };
                }
              }

              attachments[stageId].fileUrl = filesArr;
            }
          }
        } else {
          // Attached authorization/any other attachment if got any.
          if (data.ApplicationAttachment.length > 0) {
            for (const attn of data.ApplicationAttachment) {
              if (attn.oriFileName) {
                this.otherRefundEmailAttachments[attn.oriFileName] =
                  attn.oriFileName;
              }
            }
          }

          for (const stageId of stagePriorities) {
            let filesArr = [];
            let checkMultipleAtch = [];
            const attachment = data.ApplicationAttachment.filter(
              (item) => item.stageId == stageId
            );

            if (attachment.length > 0) {
              for (const atch of attachment) {
                checkMultipleAtch.push(atch.stageId);
                // Cater for other refund attachments.
                if (
                  stageId ==
                  this.hrdcData.stagePriority.refundEmailOtherAttachments
                ) {
                  if (!attachments[stageId]) {
                    attachments[stageId] = {};
                  }

                  attachments[stageId][otherRefundAttnCount] = {
                    fileUrl: atch.fileUrl,
                    fileName: atch.oriFileName,
                  };
                  otherRefundAttnCount++;
                } else {
                  filesArr.push(atch.fileUrl);

                  if (checkMultipleAtch.length > 1) {
                    // Get attachment display name based on stageId.
                    switch (atch.stageId) {
                      case this.hrdcData.stagePriority.creditNoteGenerated:
                        this.refundEmailAttachments[
                          `creditNote[::]${checkMultipleAtch.length - 1}`
                        ] = `Credit Note - ${checkMultipleAtch.length}`;
                        break;

                      case this.hrdcData.stagePriority
                        .autoGeneratedTaxpodInvoice:
                        this.refundEmailAttachments[
                          `autoGeneratedInvoice[::]${
                            checkMultipleAtch.length - 1
                          }`
                        ] = `Client/taxPOD Invoice - ${checkMultipleAtch.length}`;
                        break;
                    }
                  }

                  attachments[stageId] = {
                    fileName: atch.oriFileName,
                  };
                }
              }

              attachments[stageId].fileUrl = filesArr;
            }
          }

          this.formData.oldBankAccountHolderName =
            company.oldBankAccountHolderName.toUpperCase();

          let paymentReceivedDate = data.ApplicationLog.find(
            (item) =>
              item.stageId ==
              this.hrdcData.stagePriority.paymentReceivedFromHrdc
          );

          if (paymentReceivedDate) {
            this.formData.paymentReceivedDate = moment(
              paymentReceivedDate.startDate,
              "YYYY-MM-DD HH:mm:ssZ"
            ).format("D MMMM YYYY");
          }
        }

        // Assign data section.
        this.formData.taxpodPrice = data.totalPrice;

        // Check if pricing is unsual, display warning.
        if (!this.staticTaxpodPricing.includes(Number(data.totalPrice))) {
          this.alertDanger.push("Detected Unusual Price, Please Take Note");
        }

        this.formData.manualInvoiceToHrdc = data.manualInvoiceToHrdc;

        this.formData.autoGeneratedTaxpodInvoiceNo =
          data.autoGeneratedTaxpodInvoiceNo;
        this.formData.creditNoteNo = data.creditNoteNo;
        this.formData.paymentAdviceType = data.paymentAdviceFromClientType;

        // Assign company data.
        this.formData.oldCompanyName = this.capitalizeFirstLetter(
          company.oldCompanyName
        );
        this.formData.oldSsmNo = company.oldSsmNo;
        this.formData.bankUuid = company.bankUuid;
        this.formData.oldBankAccount = company.oldBankAccount;

        this.formData.refundRemarks = data.refundRemarks;

        // Get refund email attachment.
        this.apiGetRefundAttachment.setParams({
          applicationUuid: this.$route.query._ausk,
          attachments,
        });

        this.apiGetRefundAttachment.setCallbackCompleted((response) => {
          try {
            const { status, attn } = response;
            if (status) {
              for (const item of attn) {
                // Create a Blob object from the data
                const blob = new Blob([new Uint8Array(item.dataArray)]);

                let fileName = null;
                let file = null;

                // Cater for other refund email attachment.
                if (item.other == true) {
                  switch (item.type) {
                    case "refundEmailOtherAttachments":
                      file = new File([blob], item.fileName, {
                        type: `application/${item.extension}`,
                      });

                      this.formData.refundOtherAttachments.push(file);
                      break;
                  }
                  continue;
                }

                switch (Number(item.type)) {
                  case this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice:
                    fileName = "Client/taxPOD Invoice";
                    // Create the File object
                    file = new File([blob], fileName, {
                      type: `application/${item.extension}`,
                    });

                    this.formData.autoGeneratedTaxpodInvoice.push(file);
                    break;

                  case this.hrdcData.stagePriority.creditNote:
                  case this.hrdcData.stagePriority.creditNoteGenerated:
                    fileName = "Credit Note";
                    // Create the File object
                    file = new File([blob], fileName, {
                      type: `application/${item.extension}`,
                    });

                    this.formData.creditNote.push(file);
                    break;

                  case this.hrdcData.stagePriority.paymentAdviceFromHrdc:
                    fileName = "Payment Advice From HRDC";
                    // Create the File object
                    file = new File([blob], fileName, {
                      type: `application/${item.extension}`,
                    });

                    this.formData.paymentAdviceFromHrdc = file;
                    break;

                  case this.hrdcData.stagePriority.paymentAdviceFromClient:
                    fileName = "Payment Advice From Client";
                    // Create the File object
                    file = new File([blob], fileName, {
                      type: `application/${item.extension}`,
                    });

                    this.formData.paymentAdviceFromClient = file;
                    break;
                }
              }
            }
            this.loadingDialog = !this.loadingDialog;
          } catch (err) {
            console.log(err);
          }
        });

        this.apiGetRefundAttachment.fetch();

        // Assign checklist items.
        this.assignRefundChecklistItems();
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationSingleDetail.fetch();

    this.previewOrDownloadAttachment("autoGeneratedInvoice", "preview");
  },
  methods: {
    clearErrorMsg(field = null) {
      if (!field) {
        for (let error in this.errorField) {
          this.errorField[error] = null;
        }
      } else {
        this.errorField[field] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    async previewOrDownloadAttachment(fileUrl, action) {
      this.loadingDialog = !this.loadingDialog;

      // Get the stageId for each file.
      const stageId = this.getStageIdFromFile(fileUrl);

      // Check if got specific delimeter to handle for multiple attachments.
      let multipleAttachmentIndex = 0;
      if (fileUrl.includes("[::]")) {
        multipleAttachmentIndex = fileUrl.split("[::]")[1];
      }

      this.apiGetFileAttachment.setParams({
        applicationUuid: this.formData.applicationNo,
        stageId,
      });

      // Get the file blob.
      this.apiGetFileAttachment.setCallbackCompleted(async (response) => {
        try {
          if (response.status) {
            const data = response.data;
            const applicationAttachment = data.ApplicationAttachment;

            if (applicationAttachment.length > 0) {
              const oriFileBlob =
                applicationAttachment[multipleAttachmentIndex].fileUrl;
              const attachment = oriFileBlob.split(".");
              const attachmentPathWithoutExt = attachment[0];
              const attachmentExt = attachment[1].toLowerCase();
              const fileBlob = `${attachmentPathWithoutExt}.${attachmentExt}`;

              let responseType = "blob";
              let isImage = false;
              let blobType = `application/${attachmentExt}`;
              let streamUrl = this.apiDownloadOrPreviewAttachment.url;
              if (this.isImage(fileBlob)) {
                isImage = true;
                blobType = `image/${attachmentExt}`;
              }

              await this.$axios
                .get(streamUrl, {
                  responseType,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  params: {
                    filePath: fileBlob,
                    isImage,
                  },
                })
                .then((response) => {
                  if (action == "preview") {
                    let reader = new FileReader();
                    reader.onload = (event) => {
                      // Set base64 string as image source
                      let originalDataUrl = event.target.result;
                      let modifiedDataUrl = originalDataUrl.replace(
                        /^data:application\/octet-stream/,
                        `data:${blobType}`
                      );
                      this.imageUrl = `${modifiedDataUrl}`;
                    };
                    reader.readAsDataURL(response.data);
                  }
                })
                .catch((error) => {
                  if ((error.response.status == 404) | true) {
                    this.$store.dispatch("showMessage", {
                      message:
                        "Failed to Preview/Download attachment, kindly contact administrator",
                      messageType: "error",
                      timeout: 2000,
                    });
                  }
                  this.loadingDialog = !this.loadingDialog;
                });
            } else {
              this.$store.dispatch("showMessage", {
                message: "Attachment not found!",
                messageType: "error",
                timeout: 2000,
              });
              this.loadingDialog = !this.loadingDialog;
            }
          }
          this.loadingDialog = !this.loadingDialog;
        } catch (err) {
          this.$store.dispatch("showMessage", {
            message:
              "Failed to Preview/Download attachment, kindly contact administrator",
            messageType: "error",
            timeout: 2000,
          });
          this.loadingDialog = !this.loadingDialog;
        }
      });
      this.apiGetFileAttachment.fetch();
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    checkAttachment(e, field) {
      if (e) {
        this.clearErrorMsg(field);
      }
    },
    getStageIdFromFile(file) {
      let stage;

      if (file.includes("creditNote")) {
        return this.hrdcData.stagePriority.creditNote;
      }

      if (file.includes("autoGeneratedInvoice")) {
        return this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice;
      }

      switch (file) {
        case "autoGeneratedInvoice":
          stage = this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice;
          break;
        case "creditNote":
          stage = this.hrdcData.stagePriority.creditNote;
          break;
        case "creditNoteGenerated":
          stage = this.hrdcData.stagePriority.creditNoteGenerated;
          break;
        case "ssRefundBankAccDetails":
          stage = this.hrdcData.stagePriority.applicationSubmitted;
          break;
        case "manualInvoiceToHrdc":
          stage = this.hrdcData.stagePriority.manualInvoiceToHrdc;
          break;
        case "paymentAdviceFromClient":
          stage = this.hrdcData.stagePriority.paymentAdviceFromClient;
          break;
        case "paymentAdviceFromHrdc":
          stage = this.hrdcData.stagePriority.paymentAdviceFromHrdc;
          break;
        default:
          stage = file;
          break;
      }

      return stage;
    },
    capitalizeFirstLetter(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    modalRefundEmailApproval2Submit() {
      this.refundEmailApproval2SubmitDialog =
        !this.refundEmailApproval2SubmitDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    addDropFile(e, key, fileType, matchExtType) {
      let file = e.dataTransfer.files[0];

      if (matchExtType) {
        if (!file.type.match(matchExtType)) {
          switch (matchExtType) {
            case "pdf.*":
              this.errorField[key] = "Please select pdf file format";
              break;
            case "image/*":
              this.errorField[key] = "Please select only image";
              break;
          }
          return;
        }
      }

      this.formData[key] = file;
      if (fileType) {
        this.scanPdf(e, key, fileType);
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    addDropMultipleFile(e, key, fileType, matchExtType) {
      let files = e.dataTransfer.files;

      for (const file of files) {
        if (matchExtType) {
          if (!file.type.match(matchExtType)) {
            switch (matchExtType) {
              case "pdf.*":
                this.errorField[key] = "Please select pdf file format";
                break;
              case "image/*":
                this.errorField[key] = "Please select only image";
                break;
            }
            return;
          }
        }

        this.formData[key].push(file);

        if (fileType) {
          this.scanPdf(e, key, fileType);
        }
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    scanPdf($event, fileType, displayFileInvoiceNo) {
      this.clearErrorMsg(fileType);
      this.clearErrorMsg(displayFileInvoiceNo);
      if ($event) {
        this.loadingDialog = !this.loadingDialog;
        const fd = new FormData();

        let counter = 0;
        for (const file of this.formData[fileType]) {
          fd.append(`${fileType}_${counter}`, file);
          counter++;
        }

        fd.append("fileType", fileType);
        fd.append("counter", counter);

        this.apiScanPdfText.setParams(fd);
        this.apiScanPdfText.setCallbackCompleted((response) => {
          try {
            const { data } = response;
            if (response.status) {
              this.formData[displayFileInvoiceNo] = data;
            } else {
              this.formData[displayFileInvoiceNo] = null;
              this.$store.dispatch("showMessage", {
                message: data,
                messageType: "error",
                timeout: 2000,
              });
            }
            this.loadingDialog = !this.loadingDialog;
          } catch (err) {
            console.log(err);
          }
        });
        this.apiScanPdfText.fetch();
      } else {
        // Empty the invoice no. if remove attachment.
        this.formData[displayFileInvoiceNo] = null;
      }
    },
    getFileExt(fileType) {
      if (fileType) {
        // Get the file extension type.
        const fileSplit = fileType.split("/");
        return fileSplit[1];
      }

      return fileType;
    },
    assignRefundChecklistItems() {
      for (const refundItem of this.refundChecklistItems) {
        if (refundItem.read) {
          refundItem.value = this.formData[refundItem.slug];
        }
      }

      // Assign refund bank account details.
      let refundBankAccObj = this.refundChecklistItems.find((item) => {
        return item.slug === "refundBankAccDetails";
      });

      if (refundBankAccObj) {
        refundBankAccObj.value = `Company Name: ${this.formData.oldBankAccountHolderName}<br/>Company Registration No.: ${this.formData.oldSsmNo}<br/>Bank: ${this.formData.bankUuid}<br/>Bank Account Number: ${this.formData.oldBankAccount}`;
      }
    },
    async submit() {
      const changeFileExtensions = ["paymentAdviceFromClient"];

      for (const file of changeFileExtensions) {
        if (this.formData[file]) {
          let oriFileExt = await this.getOriginalAttachmentFileExtension(
            this.formData[file]
          );

          this.formData[file] = new File(
            [this.formData[file]],
            this.formData[file].name,
            {
              type: oriFileExt,
            }
          );
        }
      }

      // Ensure all refund checklist is checked.
      if (this.refundCheckedlist.length !== this.refundChecklistItems.length) {
        this.checklistUncheckErr = true;
        return false;
      }

      this.loadingDialog = !this.loadingDialog;

      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("totalPrice", this.formData.taxpodPrice);
      fd.append(
        "refundEmailApproval2Date",
        this.formData.refundEmailApproval2Date
      );

      // Loop taxPOD invoice.
      let taxpodInvoiceCounter = 0;
      for (const taxpodInvoice of this.formData.autoGeneratedTaxpodInvoice) {
        fd.append(
          `autoGeneratedTaxpodInvoice_${taxpodInvoiceCounter}`,
          taxpodInvoice
        );
        taxpodInvoiceCounter++;
      }
      fd.append("taxpodInvoiceCounter", taxpodInvoiceCounter);

      fd.append(
        "autoGeneratedTaxpodInvoiceNo",
        this.formData.autoGeneratedTaxpodInvoiceNo
      );

      let cnCounter = 0;
      for (const cn of this.formData.creditNote) {
        fd.append(`creditNote_${cnCounter}`, cn);
        cnCounter++;
      }

      fd.append("cnCounter", cnCounter);
      fd.append("creditNoteNo", this.formData.creditNoteNo);
      fd.append(
        "paymentAdviceFromClient",
        this.formData.paymentAdviceFromClient
      );
      fd.append("paymentAdviceFromHrdc", this.formData.paymentAdviceFromHrdc);
      fd.append("paymentAdviceType", this.formData.paymentAdviceType);
      fd.append("referById", this.auth.uuid);
      fd.append("editMode", this.editMode);
      fd.append("refundRemarks", this.formData.refundRemarks);

      // Assign multiple attachments to Form Data.
      for (let i = 0; i < this.formData.refundOtherAttachments.length; i++) {
        fd.append(
          `refundOtherAttachments_${i}`,
          this.formData.refundOtherAttachments[i]
        );
      }

      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });

      this.apiSubmit.setParams(fd);
      this.apiSubmit.fetch();
    },
    modalRefundEmailReject() {
      this.loadingDialog = !this.loadingDialog;

      this.apiReject.setParams({
        applicationUuid: this.$route.query._ausk,
        userUuid: this.auth.uuid,
        rejectToStage: this.hrdcData.stagePriority.refundEmailSubmission,
        rejectStageId: this.hrdcData.stagePriority.refundEmailApproval2Rejected,
        remarks: this.formData.refundRemarks,
      });

      this.apiReject.setCallbackCompleted((response) => {
        try {
          this.loadingDialog = !this.loadingDialog;
          if (response.status) {
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });

            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
              },
            });
          }
        } catch (err) {
          this.loadingDialog = !this.loadingDialog;
          console.log(err);
        }
      });

      this.apiReject.fetch();

      this.refundEmailRejectDialog = false;
    },
  },
};
</script>
